@tailwind base;
@tailwind components;
@tailwind utilities;

/* 你的其他全局樣式 */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes boat-float {
  0%, 100% { transform: translateY(0) translateX(-50%); }
  50% { transform: translateY(-10px) translateX(-50%); }
}

.animate-boat-float {
  animation: boat-float 3s ease-in-out infinite;
}
/* 新增的動畫和樣式 */
@keyframes float {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
}

#boat {
  animation: float 3s ease-in-out infinite;
}

.item {
  font-size: 30px;
  margin: 5px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.item:hover {
  transform: scale(1.2);
}

.game-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.game-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.dashboard-link {
  display: inline-block;
  margin-bottom: 20px;
  padding: 10px;
  background-color: #4CAF50;
  color: white;
  text-decoration: none;
  border-radius: 5px;
}

.river {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
  position: relative;
  height: 200px;
  background-color: #4682B4;
  border-radius: 10px;
}

.bank {
  width: 35%;
  height: 100%;
  background-color: #8FBC8F;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: center;
}

.boat {
  position: absolute;
  bottom: 0;
  transition: left 1s ease-in-out;
  cursor: pointer;
}

.boat.left {
  left: 37%;
}

.boat.right {
  left: 59%;
}

.move-button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.move-button:hover {
  background-color: #45a049;
}

.move-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.message {
  margin-top: 20px;
  font-weight: bold;
  color: #333;
}

.game-stats {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  margin-top: 20px;
}

.stat-item {
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
}
@keyframes boat-float {
  0%, 100% { transform: translateY(0) translateX(-50%); }
  50% { transform: translateY(-10px) translateX(-50%); }
}

.animate-boat-float {
  animation: boat-float 3s ease-in-out infinite;
}

/* 可以根據需要添加更多樣式 */
